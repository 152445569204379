* {
  width: 100%;
  margin: 0;
}
body {
  width: 100%;
  background-color: #1e1e1e;
  margin: 0 auto;
  color: white;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
